import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PageNotFound from "@/views/PageNotFound";

Vue.use(VueRouter)

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/:name/:email/:uid?',
            name: 'Home',
            component: Home
        },
        {
            path: '*',
            name: 'PageNotFound',
            component: PageNotFound,
            meta: {
                title: 'Oops! Something is broken',
            }
        }
    ]
})

router.afterEach((to) => {
    to.meta.title ? document.title = to.meta.title : null
});

export default router
