<template>
  <div>
    <v-footer color="grey lighten-4" padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>{{routeParams.name}}</strong>
      </v-col>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "SubscriptionFooter",
  props: {
    routeParams: {
      type: Object,
      required: true
    },
  }
}
</script>

<style scoped>

</style>
