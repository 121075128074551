<template>
  <v-card class="mx-auto" max-width="700">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false" color="#666"></loading>

    <v-card-text>
      <p class="display-1 text--primary">
        Do you want to <span v-if="!this.unsubscribed">un</span>subscribe?
      </p>

      <p>
        <v-text-field disabled v-model="routeParams.email" label="Email" :rules="rules"
                      hide-details="auto"></v-text-field>
      </p>

      <div>
        If you <span v-if="!this.unsubscribed">un</span>subscribe, you will <span v-if="!this.unsubscribed">stop</span>
        receiving emails from us.
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="switchSubscription" :color="`${buttonColor} white--text`" elevation="1" rounded>
        <span v-if="!this.unsubscribed">un</span>subscribe
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'Unsubscribe',

  props: {
    routeParams: {
      type: Object,
      required: true
    },
  },

  computed: {
    buttonColor: function () {
      return this.unsubscribed ? 'green' : 'red'
    }
  },

  data: () => ({
    unsubscribed: false,
    rules: [
      value => !!value || 'Required.',
      value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'E-mail must be valid'
    ],
    isLoading: false,
  }),

  components: {
    Loading
  },

  methods: {
    switchSubscription: function () {
      this.isLoading = true;
      if (!this.unsubscribed) {
        this.subscribe()
      } else {
        this.unsubscribe()
      }
    },

    subscribe() {
      let vm = this;

      let params = new URLSearchParams();
      params.append('email', this.routeParams.email);

      axios.post('http://api.toolbox.media/api/v1/subscription/unsubscribe', params).then(function () {
        vm.$set(vm, 'unsubscribed', true)
        vm.$set(vm, 'isLoading', false)
        vm.$snotify.success('Successfully unsubscribed')
      }).catch(function () {
        vm.$snotify.error('Unable to process request')
        vm.$set(vm, 'isLoading', false)
      })
    },

    unsubscribe() {
      let vm = this;

      let params = new URLSearchParams();
      params.append('email', this.routeParams.email);

      axios.post('http://api.toolbox.media/api/v1/subscription/subscribe', params).then(function () {
        vm.$set(vm, 'unsubscribed', false)
        vm.$snotify.success('Successfully subscribed')
        vm.$set(vm, 'isLoading', false)
      }).catch(function () {
        vm.$snotify.error('Unable to process request')
        vm.$set(vm, 'isLoading', false)
      })
    },
  }
}
</script>

<style scoped>

</style>
