<template>
  <v-app>
    <subscription-header :routeParams="routeParams"></subscription-header>
    <subscription-main :routeParams="routeParams"></subscription-main>
    <subscription-footer :routeParams="routeParams">></subscription-footer>
  </v-app>
</template>

<script>
import SubscriptionFooter from '@/components/SubscriptionFooter'
import SubscriptionMain from '@/components/SubscriptionMain'
import SubscriptionHeader from '@/components/SubscriptionHeader'
import CryptoJS from 'crypto-js'

export default {
  name: 'Home',

  components: {
    SubscriptionFooter,
    SubscriptionMain,
    SubscriptionHeader,
  },

  computed: {
    routeParams: function () {
      let vm = this;
      let routeParams = {}

      Object.keys(this.$route.params).forEach(function (item) {
        routeParams[item] = vm.$route.params[item] ? vm.parse(vm.$route.params[item]) : vm.$route.params[item]
      });

      return routeParams
    }
  },

  methods: {
    parse(str) {
      try {
        let key = process.env.VUE_APP_KEY;
        let encrypted_json = JSON.parse(atob(str));
        let decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(key), {
          iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
        }).toString(CryptoJS.enc.Utf8);

        return decrypted.split(':').slice(-1).pop().replace(/['";]+/g, '')
      } catch (e) {
        this.$router.push({name: 'PageNotFound'})
      }
    },
  },

  mounted() {
    if (!this.routeParams.email) {
      this.$router.push({name: 'PageNotFound'})
    }

    if (this.routeParams.name) {
      document.title = `${this.routeParams.name} - ${document.title}`
    }
  }
};
</script>
