<template>
  <div>
    <v-app-bar>
      <v-toolbar-title>{{ routeParams.name }}</v-toolbar-title>
    </v-app-bar>
  </div>

</template>

<script>
export default {
  name: "SubscriptionHeader",
  props: {
    routeParams: {
      type: Object,
      required: true
    },
  }
}
</script>

<style scoped>

</style>
